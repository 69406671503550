@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback');

@tailwind base;
@tailwind components;
@tailwind utilities;

.form-switch {
    position: relative;
    @apply relative select-none;
    width: 44px;
}

.form-switch label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    height: 6px;
    border-radius: 3px;
    @apply block overflow-hidden cursor-pointer h-6 rounded-full;
}

.form-switch label > span:first-child {
    position: absolute;
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    top: 2px;
    left: 2px;
    right: 50%;
    transition: all 0.15s ease-out;
    @apply absolute block rounded-full;
}

.form-switch input[type="checkbox"]:checked + label {
    @apply bg-green-500;
}

.form-switch.partial input[type="checkbox"]:checked + label {
    @apply bg-orange-500;
}

.form-switch input[type="checkbox"]:checked + label > span:first-child {
    left: 22px;
}

.form-switch.partial input[type="checkbox"]:checked + label > span:first-child {
    left: 11px;
}

.form-switch input[type="checkbox"]:disabled + label {
    @apply cursor-not-allowed bg-slate-100 border border-slate-200;
}

.form-switch input[type="checkbox"]:disabled + label > span:first-child {
    @apply bg-slate-400;
}